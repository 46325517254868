import React from 'react'
import LogoImage from '../assets/images/logo2.png'
// import { ReactComponent as LogoTextImage } from '../assets/svgs/brands/ip-text-only.svg'
// import { ReactComponent as LogoTextImage } from '../assets/svgs/brands/ip-text-only.svg'

export function Logo() {
    return (
        <div className="logo">
            <img className="logo-img" src={LogoImage} alt="Intelligence Pool" />
        </div>
    )
}


export function LogoText({ color, isDark, size }) {
    return (
        <div className={`logo-text ${isDark ? " dd" : ""}`} style={{ color: color, fontSize: size }}>
            {/* <LogoTextImage /> */}
            Intelligence<span>&bull;</span><span style={{color: color ? "hsl(221deg 100% 66%)" : ""}} className="logo-pool">Pool</span>
        </div>
    )
}


export function LogoAndText({ isDark, size }) {
    return (
        <div className="logo-and-text">
            <Logo />
            <LogoText isDark={isDark} size={size} color={isDark ? "white" : ""} />
        </div>
    )
}


export default Logo

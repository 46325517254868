import React from 'react'
import Header from '../components/Header'
import EjukateLogo from '../assets/images/ejukate-logo.png'
import PurrpsLogo from '../assets/images/purrps-logo.png'
import Footer from '../components/Footer'
import Technologies from '../components/weclome/Technologies'
import BragPurrps from '../components/weclome/BragPurrps'
import { BannerAnimation, LoveFromPeopleAnimation } from '../components/EAnimations'
import Me from '../assets/images/me.jpg'
import MeQR from '../assets/images/meqr.jpg'
import Button, { ButtonIcon } from '../components/Button'
import { IconInsta, IconLinkedIn, IconTwitter } from '../components/Icons'
import { ReactComponent as BurrBsImage } from '../assets/svgs/brands/purrps.svg'


const iColor = ""
const iSize = 20
function WelcomeScreen() {
    const [showQr, setShowQr] = React.useState(false)
    const scrollDiv = React.createRef();

    const goToProducts = () => {
        scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    };

    const go = (link) => window.open(link, "_blank")



    return (
        <div className="welcome">
            <div className="banner-container">
                <Header border={0} bg={`linear-gradient(90deg, rgb(13, 20, 39) 0%, rgb(14, 22, 43) 34%, rgb(13, 21, 41) 65%, rgb(14, 22, 42) 100%)`} goToProducts={goToProducts} isDark={true} />
                <div className="banner">
                    <div className="bn-content">
                        <div className="bn-left">
                            <h5>Tech stuff that people want</h5>
                            <h2>We make change and people like you love to use our Pool of Technologies</h2>
                        </div>
                        <div className="bn-right">
                            <div className="bn-graphic">
                                <BannerAnimation />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bn-media-content">
                <div className="bn-media">
                    <LoveFromPeopleAnimation />
                </div>
            </div>


            <div className="content-wrapper">
                <div className="content-info section">
                    <div id="#about" className="about">
                        <div className="fancy-title">
                            <h1>Intelligence Pool</h1>
                            <div className="ab-brands">
                                <BI src={PurrpsLogo} alt="Purrps" />
                                <BI src={EjukateLogo} alt="Ejukate" />
                                <BI src={EjukateLogo} alt="Bootcamp" />
                            </div>
                        </div>
                        <p>
                            We build softwares that help people network, earn, explore, and grow businesses.
                        </p>
                    </div>
                </div>

                <BragPurrps />
                <Technologies ref={scrollDiv} />
                <div className="adv-card section">
                    <div className="inside">
                        <div className="bars">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar last"></div>
                        </div>
                        <div className="adv-content">
                            <h3>Yo it's me, Owusu</h3>
                            <h4>I just love building stuff</h4>
                            <div className="fd-photo-frame">
                                <img src={Me} alt="Owusu Appiah Kwabena" />
                            </div>
                            <p>
                                I was so tired of eating gari and sleeping, so I had to sit up and write some Javascript. Proud I hacked a small system, though it isn't NASA with HTML.
                            </p>
                            <div className="fff">
                                <h5>Follow me some 😭</h5>
                                <div style={{ display: "flex" }}>
                                    <ButtonIcon onClick={go.bind(this, 'https://a.BurrBs.com/owusuappiah/me')} icon={<BurrBsImage />} />
                                    <ButtonIcon onClick={go.bind(this, 'https://www.linkedin.com/in/owusu-appiah-kwabena-a4a82213b/')} icon={<IconLinkedIn color={iColor} size={iSize} />} />
                                    <ButtonIcon onClick={go.bind(this, 'https://www.instagram.com/charlmacarth/')} icon={<IconInsta color={iColor} size={iSize} />} />
                                    <ButtonIcon onClick={go.bind(this, 'https://www.twitter.com/iam_owusu')} icon={<IconTwitter color={iColor} size={iSize} />} />
                                </div>
                            </div>
                        </div>
                        <div className="bars">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar last"></div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showQr ?
                    <div onClick={()=> setShowQr(false)} className="modal">
                        <div className="fff-con">
                            <div className="fff-frame">
                                <img src={MeQR} alt="Owusu Appiah Kwabena - Burrbs account" />
                            </div>
                            <div className="ax">
                                <p>Scan the QR Code to follow me on BurrBs.</p>
                                <p>Or</p>
                                <a download href={MeQR}>
                                    <Button text="Download" isLink={true} />
                                </a>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <Footer />
        </div>
    )
}

const BI = ({ src, alt }) => {
    return (
        <div className="br-img">
            <img src={src} alt={alt} />
        </div>
    )
}

export default WelcomeScreen

import React from 'react'
import { ButtonPad } from '../components/Button'
import { IconLinkArrow } from '../components/Icons'
import MainWrapper from './MainWrapper'
import BurrBsImage from '../assets/svgs/brands/purrps.svg'
import BurrBsBanImage from '../assets/images/brands/purrps-banner.png'
import BurrBsImage2 from '../assets/svgs/brands/burrbs-2.svg'
import EjukateImage from '../assets/svgs/brands/eju.svg'
import EjukateBanImage from '../assets/images/brands/ejukate-feature.png'
import EjukateImage2 from '../assets/svgs/brands/eju-lo2.svg'
import BootcampImage from '../assets/svgs/brands/boot.svg'
import BootcampBanImage from '../assets/images/brands/bootbannerpng.png'
import BootcampImage2 from '../assets/svgs/brands/boot-2.svg'
import Pallete from '../assets/svgs/brands/pallete.svg'



function BrandResourceScreen() {

    const download = (link) => {

    }
    return (
        <MainWrapper
            component={
                <div className="contact brand">
                    <div className="al-banner">
                        <h2>Brand Resources</h2>
                    </div>
                    <div className="ct-content">
                        <div className="ct-exp">
                            <div className="bd-section">
                                <h5>BurrBs</h5>
                                <div className="ct-con-lists">
                                    <L onClick={download} icon={BurrBsImage} tit="Logo - 1" />
                                    <L onClick={download} icon={BurrBsImage2} tit="Logo - 2" />
                                    <L onClick={download} icon={BurrBsBanImage} tit="Banner" />
                                    <L onClick={download} icon={Pallete} tit="Pallete" />
                                </div>
                            </div>
                            <div className="bd-section">
                                <h5>Ejukate</h5>
                                <div className="ct-con-lists">
                                    <L onClick={download} icon={EjukateImage} tit="Logo - 1" />
                                    <L onClick={download} icon={EjukateImage2} tit="Logo - 2" />
                                    <L onClick={download} icon={EjukateBanImage} tit="Banner" />
                                    <L onClick={download} icon={Pallete} tit="Pallete" />
                                </div>
                            </div>
                            <div className="bd-section">
                                <h5>Bootcamp</h5>
                                <div className="ct-con-lists">
                                    <L onClick={download} icon={BootcampImage} tit="Logo - 1" />
                                    <L onClick={download} icon={BootcampImage2} tit="Logo - 2" />
                                    <L onClick={download} icon={BootcampBanImage} tit="Banner" />
                                    <L onClick={download} icon={Pallete} tit="Pallete" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}


const L = ({ icon, tit, onClick, }) => {
    return (
        <div className="ct-list brand-l">
            <div className="graphic">
                <img src={icon} alt={tit} />
            </div>
            <div className="tit">{tit}</div>
            <div className="bt">
                <a download href={icon}>
                    <ButtonPad icon={<IconLinkArrow />} onClick={onClick} text={"Download"} isLink={true} />
                </a>
            </div>
        </div>
    )
}


export default BrandResourceScreen

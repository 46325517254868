import React, { useState } from 'react'
import Button from '../components/Button'
import { IconChat, IconEmail, IconFeedback, IconPhone } from '../components/Icons'
import { Field } from '../components/Input'
import { Paths } from '../controllers/Vars'
import MainWrapper from './MainWrapper'

const size = 30
function ContactScreen() {

    const sendMail = () => {
        var yourMessage = "Hi iPooL, ";
        var subject = "Enquiry On ...";
        document.location.href = "mailto:contact@intelligencepool.com?subject="
            + encodeURIComponent(subject)
            + "&body=" + encodeURIComponent(yourMessage);
    }

    const [message, setMessage] = useState()
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [desc, setDesc] = useState('')
    const [email, setEmail] = useState('')



    const submit = () => {
        if (name === "" || desc === "" || email === "") {
            return
        }
        const t = parseInt(Math.random() * 1000)
        console.log(t)
        setMessage({ state: 1, message: "Please hold..." })
        setTimeout(() => {
            setMessage({ state: 0, message: "Submitted" })
            setEmail('')
            setDesc('')
        }, t);
    }

    return (
        <MainWrapper
            component={
                <div className="contact">
                    <div className="al-banner">
                        <h2>Contact</h2>
                    </div>
                    <div className="ct-content">
                        <div className="ct-exp">
                            <h3 className="title">How do you want to reach us?</h3>
                            <div className="ct-con-lists">
                                <L onClick={sendMail} btnText={"Open mail"} icon={<IconEmail size={size} />} tit="Email" desc="Send an email for your enquiries." />
                                <L onClick={() => window.open('https://web.whatsapp.com/send?phone=233553544513', "_blank")} btnText={"Send message"} icon={<IconChat size={size} />} tit="Chat" desc="Reach us on Whatsapp" />
                                <L onClick={() => document.location.href = "callto:+233553544513"} btnText={"Call now"} icon={<IconPhone size={size} />} tit="Call" desc="Give us a call" />
                                <L onClick={() => document.location.href = Paths.feedback} btnText={"Send feedback"} icon={<IconFeedback size={size} />} tit="Feedback" desc="You have a suggestion? Kindly share with us" />
                            </div>
                        </div>
                    </div>
                    <div className="c-form">
                        <Field value={name} onChange={(e)=> setName(e.target.value)} label={"Name *"} placeholder="Name *" />
                        <Field value={email} onChange={(e)=> setEmail(e.target.value)} label={"Email *"} placeholder="Email *" />
                        <Field value={company} onChange={(e)=> setCompany(e.target.value)} label={"Company"} placeholder="Company" />
                        <div className="field">
                            <div className="label">Description *</div>
                            <textarea value={desc} onChange={e => setDesc(e.target.value)} placeholder="Type here..."></textarea>
                        </div>
                        <div>
                            <div className="msg succ">{message?.state !== 1 ? message?.message : ""}</div>
                            <Button onClick={submit} isSolid={true} text={message?.state === 1 ? message?.message : "Submit"} />
                        </div>
                    </div>
                </div>
            }
        />
    )
}

const L = ({ icon, tit, desc, btnText, onClick }) => {
    return (
        <div className="ct-list">
            <div className="hit">
                <div className="graphic">{icon}</div>
                <div className="tit">{tit}</div>
                <div className="desc">{desc}</div>
            </div>
            <div className="bt">
                <Button onClick={onClick} text={btnText} isLink={true} />
            </div>
        </div>
    )
}

export default ContactScreen

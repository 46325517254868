import React from 'react'

import Lottie from "lottie-react";
import LottiePay from "../assets/lottie/pays.json";
import LottieBanner from "../assets/lottie/banner.json";
import LottiePeopleLove from "../assets/lottie/peoplelove.json";
import LottiePeople from "../assets/lottie/people1.json";

export const BannerAnimation = () => {
    return <Lottie animationData={LottieBanner} />;
};


export const PayOutAnimation = () => {
    return <Lottie animationData={LottiePay} />;
};

export const LoveFromPeopleAnimation = () => {
    return <Lottie animationData={LottiePeopleLove} />;
};

export const PeopleAnimation = () => {
    return <Lottie animationData={LottiePeople} />;
};


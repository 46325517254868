import React from 'react'

function Input({ placeholder, value, onChange }) {
    return (
        <div>
            <input type="text" className="input" placeholder={placeholder} value={value} onChange={onChange} />
        </div>
    )
}

export const Field = ({ placeholder, value, onChange, label }) => {
    return (
        <div className="field">
            <div className="label">{label}</div>
            <Input value={value} onChange={onChange} placeholder={"Type here..."} />
        </div>
    )
}



export default Input

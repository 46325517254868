import React from 'react'
import { Paths } from '../controllers/Vars'
import { ButtonIcon, Link } from './Button'
import { IconFb, IconInsta, IconLinkedIn, IconTwitter, IconYt } from './Icons'
import { LogoText } from './Logo'

function Footer() {
    const iColor = ""
    const iSize = 20

    const go = (link)=> window.open(link, "_blank")

    return (
        <div className="footer">
            <div className="ft-bucket">
                <div className="awkward">
                    <LogoText size={20} />
                    <div className="ff-content">
                        <h5>Follow us</h5>
                        <div className="ff-on">
                            <ButtonIcon onClick={go.bind(this, 'https://www.linkedin.com/company/intelligence-pool')} icon={<IconLinkedIn color={iColor} size={iSize} />} />
                            <ButtonIcon onClick={go.bind(this, 'https://www.facebook.com/intelligencepool')}  icon={<IconFb color={iColor} size={iSize} />} />
                            <ButtonIcon onClick={go.bind(this, 'https://www.instagram.com/intelligence_pool/')} icon={<IconInsta color={iColor} size={iSize} />} />
                            <ButtonIcon onClick={go.bind(this, 'https://www.twitter.com/intelligence-pool')} icon={<IconTwitter color={iColor} size={iSize} />} />
                            {/* <ButtonIcon onClick={go.bind(this, 'https://www.linkedin.com/company/intelligence-pool')} icon={<IconYt color={iColor} size={iSize} />} /> */}
                        </div>
                    </div>
                </div>
                <div className="ft-list">
                    <h4>Company</h4>
                    <div className="ft-lists">
                        <Link url={Paths.about} text={"About"} />
                        <Link url={Paths.contact} text={"Contact"} />
                        <Link url={Paths.brand} text={"Brand resources"} />
                        <Link url={Paths.terms} text={"Terms of Service"} />
                        {/* <Link url={"/privacy-policy"} text={"Privacy Policy"} />
                        <Link url={"/cookie-policy"} text={"Cookie Policy"} /> */}
                    </div>
                </div>
                <div className="ft-list">
                    <h4>Products</h4>
                    <div className="ft-lists">
                        <Link blank={true} url={Paths.burrbs} text={"BurrBs"} />
                        <Link blank={true} url={Paths.ejukate} text={"Ejukate"} />
                        <Link blank={true} url={Paths.bootcamp} text={"Boot"} />
                        <Link blank={true} url={Paths.adeyhia} text={"Adeyhia"} />
                    </div>
                </div>
                <div className="ft-list">
                    <h4>Help</h4>
                    <div className="ft-lists">
                        <Link blank={true} url={"https://burrbs.com/help"} text={"Purrps Help Center"} />
                        <Link blank={true} url={"https://welcome.ejukate.com/help"} text={"Ejukate Help Center"} />
                        <Link url={Paths.feedback} text={"Feedback"} />
                    </div>
                </div>
            </div>
            <div className="ft-cp">
                <div className="">
                    &copy; 2021 INTELLIGENCE POOL
                </div>
            </div>
        </div>
    )
}

export default Footer

import React from 'react'
import Header from '../components/Header'
import { IconArrowDown } from '../components/Icons'
import MainWrapper from './MainWrapper'

function AboutScreen() {
    return (
        <MainWrapper
            // noHeader={true}
            component={
                <div className="about-content">
                    <div className="ab-banner">
                        {/* <Header isDark={true} /> */}
                        <div className="ab-ban-texts">
                            <h1>#About us</h1>
                            <div className="abt-arrow">
                                <IconArrowDown size={100} color={"var(--white)"} />
                            </div>
                        </div>
                        <div className="ab-hung-content">
                            <div className="ab-hung">
                                <p className="p1">We are bringing shows and contests online and we are making it social and simple.</p>
                                <p className="p2">Giveaways are fun!</p>
                            </div>
                        </div>
                    </div>
                    <div className="ab-content">
                        <div className="ab-lists">
                            <div className="dr t"></div>
                            <div className="ab-inside">
                                <List
                                    title={"Vision"}
                                    // desc={"The mission of Intelligence Pool is to produce diverse tools and services that will connect world's content creators and audiences, and provide outstanding"}   
                                    desc={"In the process of creating diverse businesses, we see to create more job opportunites and be a part of the huge global workforce."}
                                />
                                <List
                                    title={"Mission"}
                                    desc={"The mission of Intelligence Pool is to bring on diverse tools and services that will help contribute to the technological and societal change, POSITIVELY, around the world."}
                                />
                                <List
                                    title={"Values"}

                                    desc={
                                        <div>
                                            <p>Though Intelligence Pool is a startup</p>
                                            <div className="push">
                                                <Lt
                                                    title="Diversity"
                                                    desc="Equality is a core value at Intelligence Pool. We believe that businesses can be powerful platforms for social change and that our higher purpose is to drive Equality for all. Creating a culture of Equality isn’t just the right thing to do, it’s also the smart thing. Diverse companies are more innovative and better positioned to succeed in the Fourth Industrial Revolution.
                                                    We strive to create workplaces that reflect the communities we serve and where everyone feels empowered to bring their full, authentic selves to work. "
                                                />
                                                <Lt
                                                    title="Creativity"
                                                    desc={
                                                        <div>
                                                            “Creativity requires input, and that’s what research is. You’re gathering material with which to build.” - <small><i>Gene Luen Yang</i></small>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                            <div className="dr b"></div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}


const List = ({ title, graphic, desc }) => {
    return (
        <div className="ab-list">
            <h3>{title}</h3>
            <div className="ab-graphic">{graphic}</div>
            <div className="ab-desc">{desc}</div>
        </div>
    )
}


const Lt = ({ title, desc }) => {
    return (
        <div className="ab-lt">
            <h4>{title}</h4>
            <div className="ab-lt-desc">{desc}</div>
        </div>
    )
}

export default AboutScreen

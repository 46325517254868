import React from 'react'
import Card from '../cards/Card'
import PurrpsLogo from '../../assets/svgs/brands/purrps.svg'
import EjukateLogo from '../../assets/svgs/brands/eju.svg'
import AdeyhiaLogo from '../../assets/svgs/brands/adeyhia.svg'
import BootLogo from '../../assets/svgs/brands/boot.svg'
import { Paths } from '../../controllers/Vars'



const Technologies = React.forwardRef((props, ref) => {
    const go = (link) => {
        window.open(link, "_blank")
    }
    return (

        <div ref={ref} className="tc-items-content section">
            <div className="sc-content">
                <div className="grid-content fancy-boxes ">
                    {
                        Array(40).fill(1).map((a, i) => {
                            return (
                                <div key={i} className="fx-box"></div>
                            )
                        })
                    }
                </div>
                <div className="title grid-content f">
                    <div style={{ height: "auto", transform: "scale(1)", boxShadow: "none" }} className="info-card kk">Products</div>
                </div>
                <div className="grid-content">
                    <Card
                        onClick={go.bind(this, Paths.burrbs)}
                        url={PurrpsLogo}
                        title="BurrBs"
                        description="BurrBs is a platform where people can connect and have fun. We are bringing shows or contests online by making it more fun, social and simple."
                    />
                    <Card
                        onClick={go.bind(this, Paths.ejukate)}
                        url={EjukateLogo}
                        title="Ejukate"
                        description="Ejukate is a platform that offers tools and services to educational institutions and individual teachers. "
                    />
                    <Card
                        onClick={go.bind(this, Paths.bootcamp)}
                        url={BootLogo}
                        title={<div>I&bull;P Bootcamp</div>}
                        description="IP Bootcamp is an intensive, full-time international bootcamp geared toward entrepreneurs who want to gain coding skills."
                    />
                    <Card
                        onClick={go.bind(this, Paths.adeyhia)}
                        url={AdeyhiaLogo}
                        title="Adeyhia"
                        description="Buy from anywhere you are without being overwhelmed with a plethora of products collections."
                    />
                </div>
                <div className="grid-content fancy-boxes" style={{ marginTop: "100px" }}>
                    {
                        Array(40).fill(1).map((a, i) => {
                            return (
                                <div key={i} className="fx-box"></div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
})

export default Technologies

import React, { useState } from 'react'
import Button from '../components/Button'
import { Field } from '../components/Input'
import BurrBsImage from '../assets/svgs/brands/purrps.svg'
import EjukateImage from '../assets/svgs/brands/eju.svg'
import BootcampImage from '../assets/svgs/brands/boot.svg'
import AdeyhiaImage from '../assets/svgs/brands/adeyhia.svg'
import MainWrapper from './MainWrapper'

function ContactScreen() {
    const [product, setProduct] = useState()
    const [message, setMessage] = useState()
    const [subject, setSubject] = useState('')
    const [desc, setDesc] = useState('')
    const [email, setEmail] = useState('')

    const sel = (item) => {
        setProduct(item)
    }


    const submit = () => {
        if(subject === "" || desc === "" || email === ""){
            return
        }
        const t = parseInt(Math.random() * 1000)
        console.log(t)
        setMessage({ state: 1, message: "Please hold..." })
        setTimeout(() => {
            setMessage({ state: 0, message: "Submitted" })
            setSubject('')
            setEmail('')
            setDesc('')
        }, t);
    }

    return (
        <MainWrapper
            component={
                <div className="contact feedback">
                    <div className="al-banner">
                        <h2>Feedback</h2>
                    </div>
                    <div className="ct-content">
                        <div className="ct-exp">
                            <h3 className="title">Select an item</h3>
                            <p>We appreciate your feedback. Please select an item you want to give your suggestion on.</p>
                            <div className="ct-con-lists">
                                <L checked={product === 1} onClick={sel.bind(this, 1)} url={BurrBsImage} tit="BurrBs" />
                                <L checked={product === 2} onClick={sel.bind(this, 2)} url={EjukateImage} tit="Ejukate" />
                                <L checked={product === 3} onClick={sel.bind(this, 3)} url={BootcampImage} tit="IP Boot" />
                                <L checked={product === 4} onClick={sel.bind(this, 4)} url={AdeyhiaImage} tit="Adeyhia" />
                            </div>
                        </div>
                    </div>
                    <div className="c-form">
                        <Field value={subject} onChange={(e)=> setSubject(e.target.value)} label={"Subject *"} />
                        <Field value={email} onChange={(e)=> setEmail(e.target.value)} label={"Email *"} />
                        <div className="field">
                            <div className="label">Description *</div>
                            <textarea value={desc} onChange={(e)=> setDesc(e.target.value)} placeholder="Type here..."></textarea>
                        </div>
                        <div>
                            <div className="msg succ">{message?.state !== 1 ? message?.message : ""}</div>
                            <Button disabled={product == null} onClick={submit} isSolid={true} text={message?.state === 1 ? message?.message : "Submit"} />
                        </div>
                    </div>
                </div>
            }
        />
    )
}

const L = ({ url, tit, checked, onClick }) => {
    return (
        <div onClick={onClick} className={`ct-list fdc${checked ? ' checked' : ''}`}>
            <div className="graphic"><img src={url} alt={tit} /></div>
            <div className="tit">{tit}</div>
        </div>
    )
}

export default ContactScreen

import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

function MainWrapper({ component, isDark, noHeader }) {

    const scrollDiv = React.createRef();

    const goToProducts = () => {
        scrollDiv.current.scrollIntoView({ behavior: "smooth" });
    };

    React.useEffect(() => {
        goToProducts()
        return () => {
        }
    }, [])

    return (
        <div ref={scrollDiv}>
            <React.Fragment>
                {noHeader ? null : <Header isDark={isDark} />}
                {component}
                <Footer />
            </React.Fragment>
        </div>
    )
}

export default MainWrapper

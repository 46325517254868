import React from 'react'
import { ButtonPad } from '../Button'
import { PeopleAnimation } from '../EAnimations'
import { IconLinkArrow } from '../Icons'

function BragPurrps() {


    return (
        <div className="classic-info">
            <div className="cl-wrp">
                <div className="clc-info">
                    <h3>What <span>BurrBs</span> does</h3>
                    <h2>Paid out $20,000 to users on <span>Shows</span></h2>
                    <p>Just like you, we also entertain people by organizing interesting contests for users on BurrBs.</p>
                    <p style={{ paddingTop: "10px" }}>There's absolutely no limit to creating yours now!</p>
                    <a target="_blank" rel="noreferrer" href={"https://burrbs.com/"}>
                        <ButtonPad icon={<IconLinkArrow />} isLink={true} text="Get the app" />
                    </a>
                </div>
                <div className="clc-box">
                    <PeopleAnimation />
                </div>
            </div>
        </div>
    )
}

export default BragPurrps

import React from 'react'
import { ButtonPad } from '../Button'
import { IconLinkArrow } from '../Icons'

function Card({ title, url, description, onClick }) {
    return (
        <div className="info-card">
            <div className="info-c-in">
                <div className="in-cd-graphic">
                    <img src={url} alt={title} />
                </div>
                <div className="in-cd-title">{title}</div>
                <div className="in-cd-subtitle">{description}</div>
            </div>
            <div className="in-cd-btn-content">
                <ButtonPad onClick={onClick} icon={<IconLinkArrow />} isLink={true} text="Learn more" />
            </div>
        </div>
    )
}

export default Card

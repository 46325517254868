import React from 'react'
import { NavLink } from 'react-router-dom'
import { Paths } from '../controllers/Vars'
import { ButtonIcon } from './Button'
import { IconClose, IconMenu } from './Icons'
import { LogoAndText } from './Logo'

function Header({ isDark = false, goToProducts, bg, border }) {

    const [showMenu, setShowMenu] = React.useState(false)

    return (
        <React.Fragment>
            <div style={{ background: bg, border: border }} className={`header ${isDark ? "header-dark" : ""}`}>
                <div className="hd-bucket">
                    <div className="hd-left">
                        <NavLink to={Paths.home}>
                            <div className="hd-logo">
                                <LogoAndText isDark={isDark} />
                            </div>
                        </NavLink>
                    </div>
                    <div className="hd-right">
                        <div className="hd-links web-hd-links">
                            <Link url={Paths.about} text="About" />
                            <Link link={true} url={Paths.burrbs} text="BurrBs" />
                            <Link link={true} url={Paths.ejukate} text="Ejukate" />
                            <Link url={Paths.contact} text="Get in touch" />
                            {/* <div onClick={goToProducts} className="hd-link">
                                <div className="hd-lk-icon"></div>
                                <div className="hd-lk-text">Products</div>
                            </div> */}
                            {/* <Link url={"/us"} text="Resources" />
                            <Link url={"/us"} text="Forum" /> */}
                        </div>
                        <div className="mobile-hd-menu-btn">
                            <ButtonIcon onClick={() => setShowMenu(true)} icon={<IconMenu size={30} color={isDark ? "white" : ""} />} />
                        </div>
                    </div>
                </div>
            </div>
            {
                showMenu ?
                    <div className="block-menu">
                        <div className="bl-mn-dutton">
                            <LogoAndText isDark={isDark} />
                            <ButtonIcon onClick={() => setShowMenu(false)} icon={<IconClose color="black" />} />
                        </div>
                        <div className="bl-mn-links">
                            <Link url={Paths.about} text="About" />
                            <Link link={true} url={Paths.burrbs} text="BurrBs" />
                            <Link link={true} url={Paths.ejukate} text="Ejukate" />
                            <Link url={Paths.contact} text="Get in touch" />
                        </div>
                    </div>
                    : null
            }
        </React.Fragment>
    )
}


const Link = ({ url, icon, text, link }) => {
    return (
        link ?
            <a rel="noreferrer" target="_blank"  href={url}>
                <div className="hd-link">
                    <div className="hd-lk-icon">{icon}</div>
                    <div className="hd-lk-text">{text}</div>
                </div>
            </a>
            :
            <NavLink to={url} >
                <div className="hd-link">
                    <div className="hd-lk-icon">{icon}</div>
                    <div className="hd-lk-text">{text}</div>
                </div>
            </NavLink>
    )
}

export default Header

import React from 'react'
import { NavLink } from 'react-router-dom'

function Button({ onClick, disabled, text, space, className, component, isSolid, isLink }) {
    const cName = `button ${className}${isSolid ? " solid" : ""}${isLink ? " link" : ""}`
    return (
        <div  className={cName}
            style={{ padding: space, opacity: disabled ? 0.3 : 1 }}
            onClick={disabled ? null : onClick}>
            {component ? component : <div className="btn-text">{text}</div>}
        </div>
    )
}


export function ButtonIcon({ onClick, icon }) {
    return (
        <Button
            className={"icon"}
            onClick={onClick}
            component={<div className="btn-icon">{icon}</div>}
        />
    )
}


export function ButtonPad({ onClick, icon, text, isSolid, isLink }) {
    return (
        <Button
            isSolid={isSolid}
            className="pad"
            onClick={onClick}
            isLink={isLink}
            component={
                <React.Fragment>
                    <div className="btn-icon">{icon}</div>
                    <div className="btn-text">{text}</div>
                </React.Fragment>
            }
        />
    )
}


export function Link({ url, icon, text, space, blank }) {
    return (
        blank ?
            <a rel="noreferrer" target="_blank" href={url}>
                <Button isLink={false} text={text} space={space} />
            </a>
            :
            <NavLink to={url}>
                <Button isLink={false} text={text} space={space} />
            </NavLink>
    )
}



export default Button

import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Paths } from './controllers/Vars';
import AboutScreen from './screens/AboutScreen';
import BrandResourceScreen from './screens/BrandResourceScreen';
import ContactScreen from './screens/ContactScreen';
import FeedbackScreen from './screens/FeedbackScreen';
import TermsScreen from './screens/TermsScreen';
import WelcomeScreen from './screens/WelcomeScreen';

const p = Paths

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={p.home}>
          <WelcomeScreen />
        </Route>
        <Route path={p.about}>
          <AboutScreen />
        </Route>
        <Route path={p.contact}>
          <ContactScreen />
        </Route>
        <Route path={p.feedback}>
          <FeedbackScreen />
        </Route>
        <Route path={p.terms}>
          <TermsScreen />
        </Route>
        <Route path={p.brand}>
          <BrandResourceScreen />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
